import "./layout-switch.scss"

import React from "react"

import IcoLayoutColumns from "assets/images/ico-layout-columns.svg"
import IcoLayoutRows from "assets/images/ico-layout-rows.svg"

const LayoutSwitch = ({ layout, setLayout }) => {
  // columns, rows

  return (
    <div className="layout-switch">
      <button
        className={layout === "columns" ? "current" : ""}
        onClick={() => setLayout("columns")}
      >
        <img src={IcoLayoutColumns} alt="" />
      </button>
      <button
        className={layout === "rows" ? "current" : ""}
        onClick={() => setLayout("rows")}
      >
        <img src={IcoLayoutRows} alt="" />
      </button>
    </div>
  )
}

export default LayoutSwitch
