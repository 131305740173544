import "styles/pages/favourites.scss"

import React, { useState } from "react"
import { graphql } from "gatsby"

import Layout from "components/layout"
import Seo from "components/seo"
import Breadcrumbs from "components/Breadcrumbs/Breadcrumbs"
import PageHeader from "components/PageHeader/PageHeader"
import LayoutSwitch from "components/LayoutSwitch/LayoutSwitch"
import FavouritesList from "components/FavouritesList/FavouritesList"

const Favourites = ({ data }) => {
  const title = "Ulubione"
  const [layout, setLayout] = useState("columns")
  const allProducts = data.allWpProduct.nodes

  const favouritesData = {
    allProducts,
    layout,
    setLayout,
  }

  return (
    <Layout>
      <Seo title={title} />
      <Breadcrumbs title={title} />

      <section className="favourites">
        <div className="container-fluid">
          <PageHeader title={title} />
          <div className="favourites__filters">
            <div>
              <LayoutSwitch {...favouritesData} />
            </div>
          </div>

          <FavouritesList {...favouritesData} />
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query {
    allWpProduct(
      filter: {
        productCategories: {
          nodes: { elemMatch: { slug: { nin: "private-category" } } }
        }
      }
    ) {
      nodes {
        id
        databaseId
        name
        slug
        image {
          sourceUrl
          localFile {
            publicURL
          }
        }

        ... on WpSimpleProduct {
          price
          salePrice
        }
      }
    }
  }
`

export default Favourites
