import React from "react"
import { useFavoriteProducts } from "context/favorite_products"
import ProductTile from "components/ProductTile/ProductTile"

const FavouritesList = ({ layout }) => {
  const { favorite_products } = useFavoriteProducts()

  return (
    <div
      className={`row row__products row__products--favourites row__products--favourites--${layout}`}
    >
      {favorite_products.length > 0 ? (
        favorite_products.map((item, index) => (
          <div
            className={layout === "columns" ? "col-md-3" : "col-md-12"}
            key={index}
          >
            <ProductTile
              className={layout === "rows" && "product-tile__rows"}
              id={item.id}
              databaseId={item.databaseId}
              slug={item.slug}
              title={item.name}
              image={item.image}
              price={item.price}
              promoPrice={item.salePrice}
              stockQuantity={item.stockQuantity}
              favouriteType
              product_data={item}
            />
          </div>
        ))
      ) : (
        <p className="row__products-empty-info">
          Brak produktów w <strong>ulubionych</strong>.
        </p>
      )}
    </div>
  )
}

export default FavouritesList
